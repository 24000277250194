<div
  class="mx-auto my-10 flex w-fit cursor-pointer flex-row items-center justify-center gap-2 rounded-full border border-cg-anthracite-light-30 px-4 py-2 font-roboto-condensed text-[13px]"
  (click)="selectAppointment(appointment.id)"
>
  <cg-icon class="h-6 w-6" [content]="calendarIcon"></cg-icon>
  <div class="flex flex-col">
    <span class="font-bold">{{ "newAppointment.oneAppointmentView.title" | transloco }}</span>
    <span>
      <strong>
        {{ appointment.start | date: "EE" }}
      </strong>
      {{ appointment.start | date: "dd.LLL" }}
      <mark class="rounded border border-cg-yellow bg-cg-yellow-light-95 px-1 py-0.5">
        {{ appointment.start | date: "HH:ss" }} - {{ appointment.end | date: "HH:ss" }}
      </mark>
    </span>
  </div>
</div>
