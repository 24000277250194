import { DestroyRef, inject, Injectable } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Actions, ofType } from "@ngrx/effects";
import { catchError, map, Observable, of, switchMap, take, tap, withLatestFrom } from "rxjs";
import { AppointmentActions, AppointmentFacade, ContactDataFacade, ProcessFacade } from "@cg/olb/state";
import { TranslocoService } from "@jsverse/transloco";
import { addWeeks, endOfISOWeek, format, isSameWeek, startOfISOWeek, subDays } from "date-fns";
import { de } from "date-fns/locale";
import { OptimizelyService } from "@cg/analytics";
import { ABTest } from "@cg/core/utils";
import { Appointment, SetAppointmentPayload } from "@cg/shared";
import { OptimizelyExperiment } from "@cg/core/enums";

@Injectable({
  providedIn: "root"
})
@ABTest(OptimizelyExperiment.OLB_EARLY_CONTACT_DATA_FULL)
@ABTest(OptimizelyExperiment.OLB_EARLY_CONTACT_DATA_ONLY_MAIL)
export class NewAppointmentTileService {
  public destroyRef = inject(DestroyRef);

  public constructor(
    private readonly translocoService: TranslocoService,
    private readonly appointmentFacade: AppointmentFacade,
    private readonly processFacade: ProcessFacade,
    private readonly actions$: Actions,
    private readonly optimizelyService: OptimizelyService, // AB-Test: OLB_EARLY_CONTACT_DATA
    private readonly contactDataFacade: ContactDataFacade // AB-Test: OLB_EARLY_CONTACT_DATA
  ) {}

  public getWeekTitle({ customerAppointmentStart }: Appointment) {
    const today = new Date(Date.now());
    const appointmentDate = new Date(customerAppointmentStart);

    if (isSameWeek(today, appointmentDate)) {
      return this.translocoService.translate("newAppointment.appointmentSelection.tabTitle.thisWeek");
    } else if (isSameWeek(addWeeks(today, 1), appointmentDate)) {
      return this.translocoService.translate("newAppointment.appointmentSelection.tabTitle.nextWeek");
    } else {
      const startOfFirstWeek = startOfISOWeek(appointmentDate);
      const endOfFirstWeek = subDays(endOfISOWeek(appointmentDate), 1);

      const startDate = format(startOfFirstWeek, "dd. LLL.", { locale: de });
      const endDate = format(endOfFirstWeek, "dd. LLL.", { locale: de });

      return `${startDate} - ${endDate}`;
    }
  }

  public bookAppointment(appointment: Appointment): void {
    this.actions$
      .pipe(
        ofType(AppointmentActions.confirmAppointmentSuccess),
        take(1),
        switchMap(() => this.shouldShowNormalContactDataComponent()), // AB-Test: OLB_EARLY_CONTACT_DATA
        tap((showNormalContactData: boolean) => {
          this.processFacade.goForward(showNormalContactData ? "customer-contact" : "customer-address");
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();

    const confirmAppointment: SetAppointmentPayload = {
      ...appointment,
      source: "OLB"
    };

    this.appointmentFacade.setAppointmentId(appointment.appointmentId);
    this.appointmentFacade.confirmAppointment(confirmAppointment);
  }

  // AB-Test: OLB_EARLY_CONTACT_DATA
  // Show normal contact data, when none of the early data AB-test variants is active
  // or when email or mobile are still missing (possible in save and restore case)
  private shouldShowNormalContactDataComponent(): Observable<boolean> {
    return this.optimizelyService.isVariationOfExperimentActive(OptimizelyExperiment.OLB_EARLY_CONTACT_DATA_FULL).pipe(
      withLatestFrom(
        this.optimizelyService.isVariationOfExperimentActive(OptimizelyExperiment.OLB_EARLY_CONTACT_DATA_ONLY_MAIL),
        this.contactDataFacade.email$,
        this.contactDataFacade.mobile$
      ),
      take(1),
      map(
        ([earlyContactDataFullActive, earlyContactDataOnlyMailActive, email, mobile]: [
          boolean,
          boolean,
          string,
          string
        ]): boolean =>
          (!earlyContactDataFullActive && !earlyContactDataOnlyMailActive) ||
          earlyContactDataOnlyMailActive ||
          !email ||
          !mobile
      ),
      catchError(() => of(true))
    );
  }
}
