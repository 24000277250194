import { ChangeDetectorRef, Directive, Inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormGroup } from "@angular/forms";
import { combineLatest, Observable, of } from "rxjs";
import { distinctUntilChanged, filter, first, map, switchMap, take, withLatestFrom } from "rxjs/operators";
import { OLB_CONFIG, OlbConfiguration } from "@cg/olb/configuration";
import { GdvExitIdService } from "@cg/olb/services";
import {
  CustomerCaseFacade,
  DamageFacade,
  GdvFacade,
  InsuranceFacade,
  OlbFacade,
  ProcessFacade,
  ProductFacade
} from "@cg/olb/state";
import { OptimizelyService, TrackingEvent, TrackingService } from "@cg/analytics";
import { AddFormControls } from "@cg/core/types";
import {
  ChannelSwitchReason,
  GetGdvExitIds,
  Insurance,
  InsuranceResponse,
  InsuranceType,
  isDirectResumeFn,
  LicensePlateExitIds,
  OLB_PROCESS_FLOW_MODEL,
  ProcessFlow,
  Product,
  ScrollService
} from "@cg/olb/shared";
import { ComponentOverarchingChangeDetectionService, GetGdv, Lpn, RequiredService } from "@cg/shared";
import type { LicensePlateForm } from "./interfaces/license-form.interface";
import { OptimizelyExperiment } from "@cg/core/enums";
import { GLASSMEDIC_PRODUCT_NO } from "../../constants";
import { ExitNodeResolverService } from "../../services/exit-node-resolver.service";
import { BaseDirective } from "../core/directives/base/base.directive";

@Directive()
export class LicensePlateBase extends BaseDirective<AddFormControls<LicensePlateForm>> implements OnInit {
  public requiredService: RequiredService;
  protected fetchGdvSub: GetGdv;
  private carData: {
    selectedType: string;
    model: string;
    buildDate: string;
    manufacturer: string;
  };
  private vin: string;
  public isEditable = true;

  public get lpn(): Lpn {
    return this.form.controls.lpn.value;
  }

  // eslint-disable-next-line max-params
  public constructor(
    protected readonly cdr: ChangeDetectorRef,
    protected readonly processFacade: ProcessFacade,
    protected readonly exitNodeResolver: ExitNodeResolverService,
    protected readonly scrollService: ScrollService,
    protected readonly damageFacade: DamageFacade,
    protected readonly olbFacade: OlbFacade,
    protected readonly trackingService: TrackingService,
    protected readonly insuranceFacade: InsuranceFacade,
    protected readonly productFacade: ProductFacade,
    protected readonly gdvFacade: GdvFacade,
    protected readonly gdvExitIdService: GdvExitIdService,
    protected readonly customerCaseFacade: CustomerCaseFacade,
    protected readonly optimizelyService: OptimizelyService,
    protected readonly changeDetectionService: ComponentOverarchingChangeDetectionService,
    @Inject(OLB_CONFIG) protected readonly _olbConfig: OlbConfiguration,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);
  }

  public async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.damageFacade.requiredService$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((requiredService: RequiredService) => {
        this.requiredService = requiredService;
        this.cdr.markForCheck();
      });
    this.gdvFacade.fetch$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((fetchGdv: GetGdv) => {
      this.fetchGdvSub = fetchGdv;
      this.cdr.markForCheck();
    });
    this.productFacade.selectCarData$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((carData: { manufacturer: string; model: string; buildDate: string; selectedType: string }) => {
        this.carData = carData;
        this.cdr.markForCheck();
      });
    this.insuranceFacade.vin$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((vin: string) => {
      this.vin = vin;
      this.cdr.markForCheck();
    });
    if (isDirectResumeFn(this._olbConfig.entryChannel)) {
      this.isEditable = false;
    }
  }

  public initFormGroup(): void {
    this.form = new FormGroup<AddFormControls<LicensePlateForm>>({});
  }

  public saveForm(): void {
    this.form?.markAllAsTouched();
    this.changeDetectionService.changeDetectionRequest$.next();

    // can happen when called from ngOnDestroy when form is destroyed
    // if so we don't want to save lpn because it is always {} in this case
    if (this.lpn && Object.keys(this.lpn).length > 0 && this.form.controls.lpn.valid) {
      this.damageFacade.setLpn(this.lpn);
    }

    if (this.form.valid) {
      this.olbFacade.checkDuplicate();

      if (
        this.requiredService === RequiredService.REPAIR ||
        (this.requiredService === RequiredService.REPLACE && this.fetchGdvSub === GetGdv.TRANSMISSION)
      ) {
        const isCarManuallySelected =
          !!this.carData.manufacturer &&
          !!this.carData.model &&
          !!this.carData.buildDate &&
          !!this.carData.selectedType;
        if (isCarManuallySelected) {
          this.resetManualCarSelectionProperties();
        }
        if (this.vin) {
          this.olbFacade.getInsurance();
        }

        this.olbFacade.getAllProducts();
      }
    } else if (this.form.controls.lpn.invalid) {
      this.trackInvalidLpn();
    }
  }

  public override goForward(): void {
    const exitId$ = this.getExitIdForSavedForm();
    if (exitId$ instanceof Observable) this.getExitIdForTile(exitId$, this.nextSuccessEventAction);
  }

  private resetManualCarSelectionProperties(): void {
    this.productFacade.setSelectedModel(null);
    this.productFacade.setSelectedManufacturer(null);
    this.productFacade.setSelectedModelType(null);
    this.productFacade.setSelectedBuildDate(null);
  }

  public override setFormValues(): void {
    if (isDirectResumeFn(this._olbConfig.entryChannel)) {
      this.form.controls.lpn.statusChanges
        .pipe(
          distinctUntilChanged(),
          filter((status: string) => status === "VALID"),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe(() => {
          this.form.controls.lpn.disable();
        });
    }

    this.damageFacade.lpn$
      .pipe(
        filter((lpn: Lpn) => !!lpn?.region && !!lpn?.letters && !!lpn?.numbers),
        distinctUntilChanged(
          (prev: Lpn, curr: Lpn) =>
            prev.region === curr.region && prev.letters === curr.letters && prev.numbers === curr.numbers
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((lpn: Lpn) => {
        lpn = { ...lpn };
        delete lpn.raw;
        this.form.controls.lpn.setValue(lpn);
        this.cdr.detectChanges();
      });
  }

  public getExitIdForSavedForm(): Observable<LicensePlateExitIds> {
    return combineLatest([
      this.insuranceFacade.insuranceResponse$,
      this.damageFacade.requiredService$,
      this.productFacade.products$,
      this.gdvFacade.fetch$
    ]).pipe(
      filter(
        ([insurance, requiredService, products, fetchGdv]: [InsuranceResponse, RequiredService, Product[], GetGdv]) =>
          this.hasFetchedAllDataForExitId(requiredService, fetchGdv, insurance, products)
      ),
      first(),
      switchMap(
        ([insurance, requiredService, products, fetchGdv]: [InsuranceResponse, RequiredService, Product[], GetGdv]) => {
          if (requiredService === RequiredService.REPLACE) {
            this.trackVehicleInformation(products);
            this.trackInsuranceCoverage(insurance, fetchGdv);
            this.trackInsuranceResult(insurance, fetchGdv);
          }

          if (requiredService === RequiredService.REPAIR) {
            return this.customerCaseFacade.duplicate$.pipe(
              filter((duplicate: boolean) => duplicate !== undefined),
              take(1),
              map((duplicate: boolean) => (duplicate ? "duplicate" : "appointment"))
            );
          }

          if (fetchGdv !== GetGdv.TRANSMISSION) {
            return of("vin");
          }

          if (insurance?.vin) {
            if (products.length !== 1) {
              return this.processFacade.channelSwitchReason$.pipe(
                withLatestFrom(
                  this.optimizelyService.isVariationOfExperimentActive(
                    OptimizelyExperiment.OPPORTUNITY_FUNNEL_PRODUCT_NOT_FOUND
                  )
                ),
                filter(([reason, _isProductNotFoundAbTestActive]: [ChannelSwitchReason, boolean]) => reason !== null),
                map(([_reason, isProductNotFoundAbTestActive]: [ChannelSwitchReason, boolean]) =>
                  isProductNotFoundAbTestActive ? "noProductFoundOpportunityAbTest" : "channelSwitch"
                )
              );
            }

            if (requiredService === RequiredService.REPLACE) {
              return this.getExitIdForReplaceCase();
            }

            return of("appointment");
          }

          return of("vin");
        }
      ),
      takeUntilDestroyed(this.destroyRef)
    ) as Observable<LicensePlateExitIds>;
  }

  public trackInvalidLpn(): void {
    if (this.form.controls.lpn.invalid) {
      const event: Partial<TrackingEvent> = {
        eventAction: "invalid-license-plate",
        eventCategory: "olb",
        eventLabel: this.form.get("lpn.region")?.value
      };
      this.trackingService.trackEvent(event);
    }
  }

  public trackVehicleInformation(products: Product[]): void {
    if (!products) {
      return;
    }

    const multipleVehicles = "multiple-options";
    const eventLabel = "automated-process";
    const notFoundLabel = "not-found";

    const payload: Partial<TrackingEvent> = {
      eventAction: "vehicle-information",
      eventLabel,
      vehicle: undefined
    };

    if (products.length === 0) {
      payload.vehicle = {
        brand: notFoundLabel,
        model: notFoundLabel,
        "vehicle-identification-source": eventLabel,
        window: notFoundLabel,
        "year-of-built": notFoundLabel
      };

      this.trackingService.trackEvent(payload);
      return;
    }

    if (products.length === 1) {
      const { brand, model, buildDate } = products[0];

      payload.vehicle = {
        brand,
        model,
        "vehicle-identification-source": eventLabel,
        window: "found",
        "year-of-built": buildDate
      };
    } else if (products.length > 1) {
      payload.vehicle = {
        brand: multipleVehicles,
        model: multipleVehicles,
        "vehicle-identification-source": eventLabel,
        window: multipleVehicles,
        "year-of-built": multipleVehicles
      };
    }

    if (products.every((product: Product) => product.carglassNr !== GLASSMEDIC_PRODUCT_NO)) {
      this.trackingService.trackEvent(payload);
    }
  }

  public trackInsuranceCoverage(insuranceResponse: InsuranceResponse, gdvResponse: GetGdv): void {
    const coverage = insuranceResponse?.coverNote && gdvResponse === GetGdv.TRANSMISSION ? "covered" : "uncovered";

    this.trackingService.trackEvent({
      eventAction: "coverage-validation",
      eventLabel: coverage,
      insurance: {
        coverage
      }
    } as Partial<TrackingEvent>);
  }

  public trackInsuranceResult(insuranceResponse: InsuranceResponse, gdvResponse: GetGdv): void {
    const event: Partial<TrackingEvent> = {
      eventAction: "insurance-result",
      eventLabel: "not-checked"
    };

    if (gdvResponse === GetGdv.NO_TRANSMISSION) {
      this.trackingService.trackEvent(event);

      return;
    }

    const coverage = insuranceResponse?.coverNote ? "coverage-confirmed" : "coverage-unconfirmed";
    const carIdentified = insuranceResponse?.vin ? "car-identified" : "car-unidentified";

    this.trackingService.trackEvent({ ...event, eventLabel: `${coverage}/${carIdentified}` });
  }

  private getExitIdForReplaceCase(): Observable<GetGdvExitIds> {
    return combineLatest([
      this.customerCaseFacade.duplicate$,
      this.insuranceFacade.insuranceResponse$,
      this.insuranceFacade.type$,
      this.insuranceFacade.selectedInsurance$
    ]).pipe(
      filter(([duplicate]: [boolean, InsuranceResponse, InsuranceType, Insurance]) => duplicate !== null),
      take(1),
      switchMap(
        ([duplicate, insuranceResponse, insuranceType, selectedInsurance]: [
          boolean,
          InsuranceResponse,
          InsuranceType,
          Insurance
        ]) =>
          of(
            this.gdvExitIdService.getExitIdForAcceptedGdvTransmission(
              duplicate,
              insuranceResponse,
              insuranceType,
              selectedInsurance
            )
          )
      ),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  private hasFetchedAllDataForExitId(
    requiredService: RequiredService,
    fetchGdv: GetGdv,
    res: InsuranceResponse,
    products: Product[]
  ): boolean {
    const fetch = fetchGdv ?? GetGdv.NO_TRANSMISSION;
    const allCaseNeccessary = !!requiredService && !!fetch;
    const isRepairCase = requiredService === RequiredService.REPAIR;
    const isReplaceCase = requiredService === RequiredService.REPLACE;
    const gdvTransmission = fetch === GetGdv.TRANSMISSION;
    const isReplaceWithGdv = isReplaceCase && gdvTransmission && !!res && (!!products || !res.vin);
    const isReplaceWithoutGdv = isReplaceCase && !gdvTransmission && !!res;

    return allCaseNeccessary && (isRepairCase || isReplaceWithGdv || isReplaceWithoutGdv);
  }
}
