/* eslint-disable @angular-eslint/use-lifecycle-interface */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormGroup, ReactiveFormsModule, UntypedFormControl, Validators } from "@angular/forms";
import { Observable, of } from "rxjs";
import { distinctUntilChanged, filter, first, take, tap } from "rxjs/operators";
import { DamageFacade, DamageState, ProcessFacade } from "@cg/olb/state";
import { ToastMessage, ToastMessageComponent } from "@cg/toast";
import { TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import { TrackingEvent, TrackingService } from "@cg/analytics";
import { AddFormControls } from "@cg/core/types";
import { environment } from "@cg/environments";
import { successIcon } from "@cg/icon";
import {
  ArrowHintService,
  ChannelSwitchReason,
  ChooseWindowExitIds,
  OLB_PROCESS_FLOW_MODEL,
  OlbHeadlineComponent,
  ProcessFlow,
  ScrollService
} from "@cg/olb/shared";
import { DamageWindow, ProcessId, RadioButtonGroup, RadioButtonGroupComponent, SplitViewComponent } from "@cg/shared";
import { ApplicationName } from "@cg/core/enums";
import { DamageAssessmentContent } from "../../interfaces/damage-assessment-content";
import { ExitNodeResolverService } from "../../services/exit-node-resolver.service";
import { DamageAssessmentTileComponent } from "../../tiles/damage-assessment-tile/damage-assessment-tile.component";
import { BaseDirective } from "../core/directives/base/base.directive";
import { DamageWindowForm } from "./interfaces/damage-window-form.interface";
import { damageWindowTileContent } from "./models/damage-window-tile-content.model";
import { damageWindowTrackingMapping } from "./models/damage-window-tracking-mapping.model";
import { otherDamageLocationOptions } from "./models/other-damage-location-options.model";

const CONTROL_NAME = "damagedWindow";

@Component({
  selector: "cg-damage-window",
  templateUrl: "./damage-window.component.html",
  styleUrls: ["./damage-window.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoPipe,
    ReactiveFormsModule,
    ToastMessageComponent,
    OlbHeadlineComponent,
    DamageAssessmentTileComponent,
    RadioButtonGroupComponent,
    SplitViewComponent
  ]
})
export class DamageWindowComponent extends BaseDirective<AddFormControls<DamageWindowForm>> {
  public otherDamageLocationOptions: RadioButtonGroup = otherDamageLocationOptions;
  public damageTileContent: DamageAssessmentContent = damageWindowTileContent;
  public showEpidemicHint = false;
  public toast: ToastMessage;

  private readonly FORWARD_DELAY_TIME = 100;

  private eventDamageMapping = damageWindowTrackingMapping;

  // eslint-disable-next-line max-params
  public constructor(
    protected readonly cdr: ChangeDetectorRef,
    protected readonly processFacade: ProcessFacade,
    protected readonly exitNodeResolver: ExitNodeResolverService,
    protected readonly trackingService: TrackingService,
    protected readonly scrollService: ScrollService,
    private readonly damageFacade: DamageFacade,
    private readonly arrowHintService: ArrowHintService,
    private readonly translocoService: TranslocoService,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);
  }

  public get damagedWindow(): DamageWindow {
    return this.form.get(CONTROL_NAME).value;
  }

  public override async ngOnInit() {
    await super.ngOnInit();

    this.showEpidemicHint = environment.features.epidemicHint;

    this.translocoService
      .selectTranslate("damageWindow.toastTitle")
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((title: string) => {
        this.toast = {
          title,
          message: "",
          color: "#3B8546",
          icon: successIcon
        };
      });

    this.arrowHintService.attachArrow();

    this.processFacade.initOlbAfterError$
      .pipe(
        filter((error: boolean) => !!error),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.form.reset();
      });

    this.processFacade.editOverlayClosed$
      .pipe(
        filter(({ processId }: { processId: ProcessId }) => processId === this.processId),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.form.reset();
      });

    this.form?.valueChanges
      .pipe(
        distinctUntilChanged(
          (formA: Partial<{ damagedWindow: DamageWindow }>, formB: Partial<{ damagedWindow: DamageWindow }>) =>
            formA.damagedWindow === formB.damagedWindow
        ),
        filter((value: Partial<{ damagedWindow: DamageWindow }>) => !!value.damagedWindow),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => this.goForwardWithDelay()); // @todo: check if goForwardFo rSpecialCases was necessary at all
  }

  public override initFormGroup(): void {
    this.form = new FormGroup<AddFormControls<DamageWindowForm>>({
      damagedWindow: new UntypedFormControl("", Validators.required)
    });
  }

  public override setFormValues(): void {
    this.damageFacade.selectedDamage$
      .pipe(
        filter((v: DamageWindow) => !!v),
        take(1),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((damagedWindow: DamageWindow) => {
        this.form.get(CONTROL_NAME).setValue(damagedWindow);
        this.cdr.markForCheck();
      });
  }

  public override saveForm(): void {
    this.damageFacade.setDamagedWindow(this.damagedWindow);
  }

  public override postSaveForm() {
    super.postSaveForm();

    if ([DamageWindow.REAR, DamageWindow.LEFT_SIDE, DamageWindow.ROOF].includes(this.damagedWindow)) {
      this.updateDamageForChannelSwitch();
    }
  }

  public override getExitIdForSavedForm(): Observable<ChooseWindowExitIds> {
    this.trackDamageWindow();

    switch (this.damagedWindow) {
      case DamageWindow.FRONT:
        this.setInfoForTheNextTile();
        return of("windscreenDamaged");
      case DamageWindow.ROOF: {
        this.setInfoForTheNextTile("SUNROOF");
        return of("skylightDamaged");
      }
      case DamageWindow.LEFT_SIDE:
        if (environment.b2b || environment.brand === ApplicationName.LFR_COBRANDED) {
          this.setInfoForTheNextTile("SIDE_WINDOW");
        }
        return of("sideWindowDamaged");
      case DamageWindow.REAR:
        if (environment.b2b || environment.brand === ApplicationName.LFR_COBRANDED) {
          this.setInfoForTheNextTile("REAR_WINDOW");
        }
        return of("rearWindowDamaged");
      case DamageWindow.SPECIAL_VEHICLE:
      case DamageWindow.MULTIPLE_WINDOWS:
        this.setInfoForTheNextTile(this.damagedWindow);
        return of("otherWindowDamaged");
      default:
        break;
    }
  }

  public goForwardWithDelay(): void {
    // current process id is checked so the delayed forward is not triggered on restore with resume id
    this.processFacade.currentProcessId$
      .pipe(
        first(),
        filter((id: ProcessId) => id === "damage-window"),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => setTimeout(() => this.goForward(), this.FORWARD_DELAY_TIME));
  }

  private setInfoForTheNextTile(channelSwitchReason: ChannelSwitchReason = null) {
    this.processFacade.setChannelSwitchReason(channelSwitchReason);
  }

  private trackDamageWindow() {
    const eventDamage = this.eventDamageMapping.get(this.damagedWindow);

    if (eventDamage) {
      let event = {
        eventAction: "damage-window-selection",
        eventLabel: eventDamage,
        damage: {
          window: eventDamage
        }
      } as Partial<TrackingEvent>;
      if (this.damagedWindow === DamageWindow.LEFT_SIDE) {
        event = {
          ...event,
          ...{
            "opportunity-funnel": {
              case: "side-window"
            }
          }
        };
      }
      if (this.damagedWindow === DamageWindow.REAR) {
        event = {
          ...event,
          ...{
            "opportunity-funnel": {
              case: "rear-window"
            }
          }
        };
      }
      this.trackingService.trackEvent(event);
    }
  }

  private updateDamageForChannelSwitch() {
    this.damageFacade.damageState$
      .pipe(
        filter((damageState: DamageState) => damageState?.damageWindow === this.damagedWindow),
        take(1),
        takeUntilDestroyed(this.destroyRef),
        tap((damageState: DamageState) => this.damageFacade.updateDamage(damageState))
      )
      .subscribe();
  }

  protected restoreFromEntryState() {
    this.goForward();
    this.cdr.markForCheck();
  }
}
