import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormGroup, ReactiveFormsModule, UntypedFormControl, Validators } from "@angular/forms";
import { Actions, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { combineLatest, Observable } from "rxjs";
import { filter, first, switchMap, take } from "rxjs/operators";
import { OLB_CONFIG, OlbConfiguration } from "@cg/olb/configuration";
import { DamageActions, DamageFacade, DamageState, InsuranceFacade, ProcessFacade, ProductFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { TrackingDamageSize, TrackingEvent, TrackingService } from "@cg/analytics";
import { AddFormControls } from "@cg/core/types";
import {
  CrackSizeExitIds,
  ExitIdService,
  Insurance,
  isDirectResumeFn,
  OLB_PROCESS_FLOW_MODEL,
  OlbHeadlineComponent,
  ProcessFlow,
  Product,
  ScrollService
} from "@cg/olb/shared";
import { DamageSize, ProcessId, SplitViewComponent } from "@cg/shared";
import { DamageAssessmentContent } from "../../interfaces/damage-assessment-content";
import { ExitNodeResolverService } from "../../services/exit-node-resolver.service";
import { DamageAssessmentTileComponent } from "../../tiles/damage-assessment-tile/damage-assessment-tile.component";
import { BaseDirective } from "../core/directives/base/base.directive";
import { DamageSizeForm } from "./interfaces/damage-size-form.interface";
import { damageSizeTileContent } from "./models/damage-size-tile-content.model";

@Component({
  selector: "cg-damage-size",
  templateUrl: "./damage-size.component.html",
  styleUrls: ["./damage-size.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe, ReactiveFormsModule, OlbHeadlineComponent, DamageAssessmentTileComponent, SplitViewComponent]
})
export class DamageSizeComponent extends BaseDirective<AddFormControls<DamageSizeForm>> implements OnInit, OnDestroy {
  protected readonly eventDamageTypeMapping: Map<DamageSize, TrackingDamageSize> = new Map<
    DamageSize,
    TrackingDamageSize
  >([
    [DamageSize.SMALL, "smaller-than-2-euro"],
    [DamageSize.BIG, "bigger-than-2-euro"]
  ]);

  public damageTileContent: DamageAssessmentContent = damageSizeTileContent;

  public get damageSize(): DamageSize {
    return this.form.controls.damageSize.value;
  }

  // eslint-disable-next-line max-params
  public constructor(
    cdr: ChangeDetectorRef,
    processFacade: ProcessFacade,
    exitNodeResolver: ExitNodeResolverService,
    scrollService: ScrollService,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow,
    protected damageFacade: DamageFacade,
    protected trackingService: TrackingService,
    protected readonly insuranceFacade: InsuranceFacade,
    protected readonly exitIdService: ExitIdService,
    protected readonly productFacade: ProductFacade,
    @Inject(OLB_CONFIG) protected _olbConfig: OlbConfiguration,
    protected readonly actions$: Actions
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);
  }

  public async ngOnInit() {
    super.ngOnInit();

    this.processFacade.currentProcessId$
      .pipe(
        filter((processId: ProcessId) => processId === this.processId),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.form.reset();
      });
  }

  public ngOnDestroy() {
    super.ngOnDestroy();

    this.damageFacade.setDamageSize(null);
  }

  public initFormGroup(): void {
    this.form = new FormGroup<AddFormControls<DamageSizeForm>>({
      damageSize: new UntypedFormControl("", Validators.required)
    });
  }

  public setFormValues(): void {
    this.damageFacade.damageSize$
      .pipe(
        filter((damageSize: DamageSize) => !!damageSize),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((damageSize: DamageSize) => {
        this.form.controls.damageSize.setValue(damageSize);
        this.cdr.markForCheck();
      });
  }

  public getExitIdForSavedForm(): Observable<CrackSizeExitIds> {
    const isDirectResume = isDirectResumeFn(this._olbConfig.entryChannel);

    return combineLatest([
      this.actions$.pipe(ofType(DamageActions.updateDamageSuccess), take(1)),
      this.insuranceFacade.selectedInsurance$,
      this.productFacade.products$,
      this.insuranceFacade.vin$
    ]).pipe(
      filter(([_action, _, products, _vin]: [Action, Insurance, Product[], string]) => !isDirectResume || !!products),
      take(1),
      switchMap(([_action, insurance, products, vin]: [Action, Insurance, Product[], string]) =>
        this.exitIdService.getExitIdForDamageSize$(this.damageSize, insurance, isDirectResume, products, vin)
      ),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  public goForward(processId: ProcessId = "damage-size"): void {
    if (this.form.valid) {
      this.trackDamageSize();
    }

    // current process id is checked so the forward is not triggered on restore with resume id
    this.processFacade.currentProcessId$
      .pipe(
        first(),
        filter((id: ProcessId) => id === processId)
      )
      .subscribe(() => {
        super.goForward();
      });
  }

  public saveForm(): void {
    this.damageFacade.setDamageSize(this.damageSize);
  }

  public postSaveForm() {
    this.updateDamage();
  }

  public updateDamage(): void {
    this.damageFacade.damageState$
      .pipe(
        filter((damageState: DamageState) => this.damageSize === damageState.damageSize),
        take(1),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((damageState: DamageState) => this.damageFacade.updateDamage(damageState));
  }

  protected trackDamageSize() {
    const damage = this.eventDamageTypeMapping.get(this.damageSize);

    this.trackingService.trackEvent({
      eventAction: "damage-size-selection",
      eventLabel: damage,
      damage: {
        size: damage
      }
    } as Partial<TrackingEvent>);
  }

  protected restoreFromEntryState() {
    this.damageFacade.damageSize$.pipe(take(1)).subscribe((damageSize: DamageSize) => {
      setTimeout(() => {
        this.form.controls.damageSize.setValue(damageSize);
        this.cdr.markForCheck();
        this.goForward();
      }, 100);
    });
  }
}
