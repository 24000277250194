import { AsyncPipe, DOCUMENT, NgClass } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { combineLatest, combineLatestWith, Observable, of, switchMap } from "rxjs";
import { distinctUntilChanged, filter, map, pairwise, take, tap, withLatestFrom } from "rxjs/operators";
import { AppointmentFacade, DamageFacade, ProcessFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { addDays, endOfISOWeek, isAfter, isBefore } from "date-fns";
import addWeeks from "date-fns/addWeeks";
import { AnalyticsEventContainerComponent, OptimizelyFeEvent, TrackingService } from "@cg/analytics";
import { AddFormControls } from "@cg/core/types";
import { HeadlineComponent, IconComponent } from "@cg/core/ui";
import { ABTest } from "@cg/core/utils";
import { phoneContactIcon } from "@cg/icon";
import { OLB_PROCESS_FLOW_MODEL, OlbHeadlineComponent, ProcessFlow, ScrollService } from "@cg/olb/shared";
import {
  Appointment,
  AppointmentData,
  AppointmentSearchForm,
  AvailableServiceCenters,
  BrandingComponent,
  OverlayService,
  ProcessId,
  RequiredService,
  SplitViewComponent
} from "@cg/shared";
import { OptimizelyExperiment, USER_DECISION } from "@cg/core/enums";
import { ExitNodeResolverService } from "../../../services/exit-node-resolver.service";
import { BaseDirective } from "../../core/directives/base/base.directive";
import { NewAppointmentEarliestAppointmentCardComponent } from "../components/new-appointment-earliest-appointment-card/new-appointment-earliest-appointment-card.component";
import { NewAppointmentMobileServiceViewComponent } from "../components/new-appointment-mobile-service-view/new-appointment-mobile-service-view.component";
import { NewAppointmentNoAppointmentsComponent } from "../components/new-appointment-no-appointments/new-appointment-no-appointments.component";
import { NewAppointmentNoScDialogComponent } from "../components/new-appointment-no-sc-dialog/new-appointment-no-sc-dialog.component";
import { NewAppointmentSearchComponent } from "../components/new-appointment-search/new-appointment-search.component";
import { NewAppointmentSelectCardComponent } from "../components/new-appointment-select-card/new-appointment-select-card.component";
import { CombinedAppointmentLocations } from "../interfaces/combined-appointment-locations.interface";
import { LocationWithAppointment } from "../interfaces/location-with-appointment.interface";
import { LocationWithAppointments } from "../interfaces/location-with-appointments.interface";
import { createAppointmentData } from "../utils/create-appointment-data.util";

@ABTest(OptimizelyExperiment.NEW_APPOINTMENT_TILE)
@Component({
  selector: "cg-new-appointment-mobile",
  templateUrl: "./new-appointment-mobile.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    AsyncPipe,
    TranslocoPipe,
    HeadlineComponent,
    ReactiveFormsModule,
    BrandingComponent,
    SplitViewComponent,
    IconComponent,
    NewAppointmentNoAppointmentsComponent,
    OlbHeadlineComponent,
    NewAppointmentMobileServiceViewComponent,
    NewAppointmentSelectCardComponent,
    NewAppointmentEarliestAppointmentCardComponent,
    NewAppointmentSearchComponent
  ],
  standalone: true
})
export class NewAppointmentMobileComponent
  extends BaseDirective<AddFormControls<AppointmentSearchForm>>
  implements OnInit, AfterViewInit, OnDestroy
{
  private static readonly EMPTY_COMBINED_APPOINTMENT_LOCATION = { selected: [], earlier: [] };
  public readonly limitDate = endOfISOWeek(addWeeks(new Date(), 6));
  public destroyRef = inject(DestroyRef);
  public appointmentData: AppointmentData;
  public searchClicked$ = this.appointmentFacade.searchClicked$;
  public currentlyExpanded = new Set<number>();

  public combinedAppointmentLocations$: Observable<CombinedAppointmentLocations>;
  public earliestLocationWithAppointment: LocationWithAppointment;

  public searchIsSticky = false;
  public shouldDisplayMobileService = false;
  public hasSelectedAppointments = false;
  public hasAnyAppointments = false;
  public selectedAllServiceCenters = false;

  @ViewChild(NewAppointmentSearchComponent, { static: false, read: ElementRef })
  public searchComponent: ElementRef;
  protected readonly phoneContactIcon = phoneContactIcon;
  private nextLoadingDate: Date = new Date();
  private observer: IntersectionObserver;
  private isSingleScInRadius = false;

  // eslint-disable-next-line max-params
  public constructor(
    protected readonly cdr: ChangeDetectorRef,
    protected readonly processFacade: ProcessFacade,
    protected readonly exitNodeResolver: ExitNodeResolverService,
    protected readonly scrollService: ScrollService,
    protected readonly trackingService: TrackingService,
    private readonly appointmentFacade: AppointmentFacade,
    private readonly damageFacade: DamageFacade,
    private readonly overlayService: OverlayService,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);
  }

  public async ngOnInit(): Promise<void> {
    this.scrollToSearch();
    this.setAppointmentData();
    this.initAppointmentLocations();
    this.initResettingCurrentlyExpandedServiceCenter();
    this.initResettingSearchClicked();
    this.initHandleNoScs();
    this.initResetOnProcessFlowBack();
    this.initSelectedAllServiceCenters();
    this.initTrackSelectedBranches();

    await super.ngOnInit();
  }

  public ngAfterViewInit(): void {
    this.initStickyObserver();
    this.initProcessFlowStickyHandling();
  }

  public ngOnDestroy(): void {
    this.appointmentFacade.setSearchClicked(false);
    this.observer?.disconnect();
  }

  public initFormGroup(): void {
    this.form = new FormGroup<AddFormControls<AppointmentSearchForm>>({
      searchServiceCenterInput: new FormControl<string>("", Validators.required),
      selectedAppointmentId: new FormControl<string>("", Validators.required)
    });
  }

  public setFormValues(): void {
    // TODO: maybe implement this later for save and restore or mycarglass
  }

  /**
   * Method is not used in this component and is only implemented to satisfy the interface.
   *
   * Processforward is handled in the `clickOk` method in the `new-appointment-detail` component.
   **/
  public getExitIdForSavedForm(): Observable<string> {
    return of("");
  }

  public saveForm(): void {}

  public search() {
    this.appointmentFacade.setSearchClicked(true);
  }

  public toggleCurrentlyExpanded(emittingCard: number) {
    if (this.currentlyExpanded.has(emittingCard)) {
      this.currentlyExpanded.delete(emittingCard);
    } else {
      this.currentlyExpanded.add(emittingCard);
    }
  }

  public getCardId(_index: number, { serviceCenter, appointments }: LocationWithAppointments): string {
    return `${serviceCenter.serviceCenter}_${appointments.length}`;
  }

  private setAppointmentData(): void {
    this.appointmentData = createAppointmentData(this.appointmentFacade, this.damageFacade);

    this.appointmentData.selectedServiceCenterIds$
      .pipe(
        filter((scIds: string[]) => scIds.length > 0 && !this.isSingleScInRadius),
        tap(() => {
          this.appointmentFacade.setSearchClicked(true);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private initSingleScInRadiusCheck(): void {
    this.appointmentFacade.availableServiceCenters$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((availableServiceCenters: AvailableServiceCenters[]) => {
          this.isSingleScInRadius = availableServiceCenters?.length === 1;
        }),
        filter((scs: AvailableServiceCenters[]) => scs?.length === 1),
        distinctUntilChanged(
          ([prev]: AvailableServiceCenters[], [cur]: AvailableServiceCenters[]) =>
            prev.serviceCenter === cur.serviceCenter
        )
      )
      .subscribe((scs: AvailableServiceCenters[]) => {
        this.appointmentFacade.setSelectedServiceCenterIds(
          scs.map(({ serviceCenter }: AvailableServiceCenters) => serviceCenter)
        );
      });
  }

  private initAppointmentLocations() {
    const currentAppointments$: Observable<Appointment[]> = this.appointmentData.availableAppointments$.pipe(
      filter((appointments: Appointment[]) => !!appointments)
    );
    const availableLocations$ = this.appointmentFacade.availableServiceCenters$.pipe(
      filter((appointmentState: AvailableServiceCenters[]) => !!appointmentState)
    );

    this.combinedAppointmentLocations$ = this.appointmentData.selectedServiceCenterIds$.pipe(
      filter((selectedCenters: string[]) => selectedCenters?.length > 0),
      tap(() => this.resetNextLoadingDate()),
      combineLatestWith(currentAppointments$, availableLocations$),
      tap(
        ([_selectedServiceCenterIds, appointments, _serviceCenters]: [
          selectedServiceCenterIds: string[],
          appointments: Appointment[],
          serviceCenters: AvailableServiceCenters[]
        ]) => this.loadMoreAppointments(appointments)
      ),
      map(
        (
          values: [
            selectedServiceCenterIds: string[],
            appointments: Appointment[],
            serviceCenters: AvailableServiceCenters[]
          ]
        ) => this.mapToCombinedAppointmentLocations(values)
      ),
      tap((locations: CombinedAppointmentLocations) => this.setEarliestLocationWithAppointment(locations)),
      tap((locations: CombinedAppointmentLocations) => this.setShouldDisplayMobileService(locations)),
      tap((locations: CombinedAppointmentLocations) => this.setHasAppointments(locations))
    );

    this.initSingleScInRadiusCheck();
  }

  private setEarliestLocationWithAppointment({ selected: selectedLocations }: CombinedAppointmentLocations): void {
    this.earliestLocationWithAppointment = this.getEarliestLocationWithAppointment(selectedLocations);
  }

  private resetNextLoadingDate(): void {
    this.nextLoadingDate = new Date();
  }

  private mapToCombinedAppointmentLocations([selectedCenterIds, availableAppointments, availableServiceCenters]: [
    string[],
    Appointment[],
    AvailableServiceCenters[]
  ]): CombinedAppointmentLocations {
    if (selectedCenterIds.length === 0) {
      return NewAppointmentMobileComponent.EMPTY_COMBINED_APPOINTMENT_LOCATION;
    }

    const allLocationWithAppointments: LocationWithAppointments[] = this.mapLocationsWithAppointments(
      availableAppointments,
      availableServiceCenters
    );

    const sortedLocationsWithAppointment = this.sortBySelectionStatus(allLocationWithAppointments, selectedCenterIds);

    const earliestSelectedAppointment = this.getEarliestAppointment(sortedLocationsWithAppointment.selected);
    if (!this.hasSelectedSpecificServiceCenters(availableServiceCenters, selectedCenterIds)) {
      this.filterEmptySelectedAppointments(sortedLocationsWithAppointment);
    }
    if (earliestSelectedAppointment) {
      const earliestSelectedTime = new Date(earliestSelectedAppointment.customerAppointmentStart);

      this.filterEarliestAppointmentsBefore(sortedLocationsWithAppointment, earliestSelectedTime);
    }
    this.sortOutDuplicateTimeAppointments(sortedLocationsWithAppointment);

    return this.sortByDistance(sortedLocationsWithAppointment);
  }

  private sortByDistance(sortedLocationsWithAppointment: CombinedAppointmentLocations): CombinedAppointmentLocations {
    const distanceSorter = (locationA: LocationWithAppointments, locationB: LocationWithAppointments) =>
      locationA.serviceCenter.distance - locationB.serviceCenter.distance;
    sortedLocationsWithAppointment.selected.sort(distanceSorter);
    sortedLocationsWithAppointment.earlier.sort(distanceSorter);
    return sortedLocationsWithAppointment;
  }

  private filterEarliestAppointmentsBefore(
    sortedLocationsWithAppointment: CombinedAppointmentLocations,
    beforeDate: Date
  ): void {
    sortedLocationsWithAppointment.earlier = sortedLocationsWithAppointment.earlier.filter(
      (appointment: LocationWithAppointments) =>
        isBefore(new Date(appointment.appointments[0].customerAppointmentStart), beforeDate)
    );
  }

  private getEarliestAppointment(location: LocationWithAppointments[]): Appointment {
    if (!location || location.length === 0) {
      return null;
    }

    const reducedLocations = location.reduce(
      (locationA: LocationWithAppointments, locationB: LocationWithAppointments) => {
        if (locationA.appointments.length === 0) {
          return locationB;
        }

        if (locationB.appointments.length === 0) {
          return locationA;
        }

        const aTime: number = new Date(locationA.appointments[0].customerAppointmentStart).getTime();
        const bTime: number = new Date(locationB.appointments[0].customerAppointmentStart).getTime();

        if (aTime < bTime) {
          return locationA;
        } else if (aTime > bTime) {
          return locationB;
        } else {
          return locationA.serviceCenter.distance < locationB.serviceCenter.distance ? locationA : locationB;
        }
      }
    );

    return reducedLocations?.appointments[0];
  }

  private loadMoreAppointments(appointments: Appointment[]): void {
    const loadingRequired = isBefore(this.nextLoadingDate, this.limitDate);
    // Used to only fully load two weeks currently we load 6 weeks so unil nextloadingDate is after limitDate
    // TODO: Maybe find a better way together with backend
    if (loadingRequired) {
      const highestFromCurrentlyLoadedAppointments = appointments
        .map((appointment: Appointment) => new Date(appointment.customerAppointmentStart))
        .reduce((a: Date, b: Date) => (a > b ? a : b), new Date());
      const otherwiseNextLoadingDate = addDays(this.nextLoadingDate, 5);
      this.nextLoadingDate = isAfter(highestFromCurrentlyLoadedAppointments, otherwiseNextLoadingDate)
        ? highestFromCurrentlyLoadedAppointments
        : otherwiseNextLoadingDate;

      this.notifyOptimizelyLoadingDone();
      this.appointmentFacade.getNextAppointments(this.nextLoadingDate);
    }
  }

  private notifyOptimizelyLoadingDone() {
    const loadingDone = !isBefore(this.nextLoadingDate, this.limitDate);

    if (loadingDone) {
      this.document
        .querySelector(`#${AnalyticsEventContainerComponent.ANALYTICS_EVENT_CONTAINER_ID}`)
        .dispatchEvent(new CustomEvent(OptimizelyFeEvent.APPOINTMENT_TILE_LOADED));
    }
  }

  private sortBySelectionStatus(
    allLocationWithAppointments: LocationWithAppointments[],
    selectedCenterIds: string[]
  ): CombinedAppointmentLocations {
    return allLocationWithAppointments.reduce(
      (sortedLocations: CombinedAppointmentLocations, location: LocationWithAppointments) => {
        if (selectedCenterIds.includes(location.serviceCenter.serviceCenter)) {
          sortedLocations.selected.push(location);
        } else if (location.appointments.length > 0) {
          sortedLocations.earlier.push(location);
        }

        return sortedLocations;
      },
      { selected: [], earlier: [] } as CombinedAppointmentLocations
    );
  }

  private mapLocationsWithAppointments(
    appointments: Appointment[],
    serviceCenters: AvailableServiceCenters[]
  ): LocationWithAppointments[] {
    return serviceCenters.map((serviceCenter: AvailableServiceCenters): LocationWithAppointments => {
      const appointmentsOfCenter = appointments.filter(
        ({ serviceCenter: serviceCenterId }: { serviceCenter: string }) =>
          serviceCenterId === serviceCenter.serviceCenter
      );
      return {
        serviceCenter,
        appointments: appointmentsOfCenter
      };
    });
  }

  private initResettingCurrentlyExpandedServiceCenter(): void {
    let scrollAction: number;

    this.combinedAppointmentLocations$
      .pipe(
        distinctUntilChanged(
          (previous: CombinedAppointmentLocations, current: CombinedAppointmentLocations) =>
            !this.isNewAssignmentRequired(previous, current)
        ),
        tap((combinedAppointmentLocations: CombinedAppointmentLocations) => {
          this.expandFirstWithAppointments(combinedAppointmentLocations);
          if (combinedAppointmentLocations.earlier.length === 0 && combinedAppointmentLocations.selected.length === 0) {
            scrollAction = this.scrollTo("appointment", scrollAction);
          } else {
            scrollAction = this.scrollTo("appointment-search-started", scrollAction, 70);
          }
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private scrollTo(elementId: ProcessId | string, scrollAction?: number, offset?: number) {
    if (scrollAction) {
      clearTimeout(scrollAction);
    }

    if (this.currentlyExpanded.size === 0) {
      return;
    }

    return window.setTimeout(() => this.scrollService.scrollToTileId(elementId as ProcessId, offset), 600);
  }

  private isNewAssignmentRequired(
    prevLocations: CombinedAppointmentLocations,
    curLocations: CombinedAppointmentLocations
  ): boolean {
    const prevLocationsString = this.locationsToIdString(prevLocations);
    const curLocationsString = this.locationsToIdString(curLocations);

    const locationsChanged = prevLocationsString !== curLocationsString;

    return this.currentlyExpanded.size === 0 || locationsChanged;
  }

  private locationsToIdString({ selected }: CombinedAppointmentLocations): string {
    return selected
      .map(({ serviceCenter: { serviceCenter: scId } }: { serviceCenter: { serviceCenter: string } }) => scId)
      .toString();
  }

  private expandFirstWithAppointments({ selected: locationsWithAppointments }: CombinedAppointmentLocations) {
    this.currentlyExpanded = new Set<number>();
    let firstWithAppointments = locationsWithAppointments.findIndex(
      ({ appointments }: { appointments: Appointment[] }) => appointments.length
    );
    if (firstWithAppointments === -1 && locationsWithAppointments.length > 0) {
      firstWithAppointments = 0;
    }
    this.currentlyExpanded.add(firstWithAppointments);
  }

  private getEarliestLocationWithAppointment(
    locationsWithAppointments: LocationWithAppointments[]
  ): LocationWithAppointment {
    if (!locationsWithAppointments || locationsWithAppointments.length === 0) {
      return null;
    }

    if (locationsWithAppointments.length === 1) {
      return null;
    }

    // TODO check for why we search this appointment again.
    const earliestAppointmentId = this.getEarliestAppointment(locationsWithAppointments)?.appointmentId;

    if (!earliestAppointmentId) {
      return null;
    }

    const earliestAppointmentWithLocation = locationsWithAppointments
      .filter(({ appointments }: { appointments: Appointment[] }) => appointments.length)
      .find((loc: LocationWithAppointments) => loc.appointments[0].appointmentId === earliestAppointmentId);

    return {
      serviceCenter: earliestAppointmentWithLocation.serviceCenter,
      appointment: earliestAppointmentWithLocation.appointments[0]
    };
  }

  private initStickyObserver(): void {
    this.observer = new IntersectionObserver(
      ([e]: [IntersectionObserverEntry]) => {
        const isSticky = e.intersectionRatio < 1 && e.boundingClientRect.bottom < screen.height;

        this.searchClicked$
          .pipe(
            take(1),
            tap((searchClicked: boolean) => {
              if (isSticky !== this.searchIsSticky && searchClicked) {
                this.searchIsSticky = isSticky && this.processId === this.currentProcessId;
                this.cdr.markForCheck();
              }
            }),
            takeUntilDestroyed(this.destroyRef)
          )
          .subscribe();
      },
      { threshold: [1] }
    );

    this.observer.observe(this.searchComponent.nativeElement);
  }

  private scrollToSearch() {
    this.scrollService.scrollToTileId("appointment");
  }

  private initResettingSearchClicked() {
    this.appointmentData.formattedAddress$
      .pipe(
        tap(() => {
          this.appointmentFacade.setSearchClicked(false);
          this.searchIsSticky = false;
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private setShouldDisplayMobileService(locations: CombinedAppointmentLocations): void {
    this.damageFacade.requiredService$
      .pipe(
        take(1),
        withLatestFrom(this.searchClicked$),
        tap(([requiredService, searchClicked]: [RequiredService, boolean]) => {
          this.shouldDisplayMobileService =
            requiredService === RequiredService.REPLACE &&
            locations.earlier.length === 0 &&
            locations.selected.length === 1 &&
            locations.selected[0].appointments.length === 1 &&
            searchClicked;

          this.cdr.markForCheck();
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private initProcessFlowStickyHandling(): void {
    this.processFacade.currentProcessId$
      .pipe(
        tap((processId: ProcessId) => {
          const searchRect = this.searchComponent.nativeElement.getBoundingClientRect();
          this.searchIsSticky = processId === this.processId && searchRect.y <= 0;
          this.cdr.detectChanges();
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private setHasAppointments(locations: CombinedAppointmentLocations) {
    this.hasSelectedAppointments = locations.selected
      .map((location: LocationWithAppointments): boolean => location.appointments.length !== 0)
      .some((hasAppointmentsA: boolean) => hasAppointmentsA);
    this.hasAnyAppointments = this.hasSelectedAppointments || locations.earlier.length > 0;
  }

  private initHandleNoScs() {
    this.appointmentFacade.hasAppointmentLoaded$
      .pipe(
        filter((loaded: boolean) => !!loaded),
        withLatestFrom(this.appointmentFacade.availableServiceCenters$),
        filter(
          ([_loaded, availableServiceCenters]: [boolean, AvailableServiceCenters[]]) =>
            !availableServiceCenters || availableServiceCenters.length === 0
        ),
        switchMap(() => {
          this.overlayService.open(NewAppointmentNoScDialogComponent);
          return this.overlayService.afterClosed$.pipe(take(1));
        }),
        tap((event: { decision: USER_DECISION }) => {
          // event === null => dialog closed
          if (!event || event.decision === USER_DECISION.ACCEPT) {
            this.resetForm();
          }
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private resetForm() {
    this.form.controls.searchServiceCenterInput.setValue(null);
    this.appointmentFacade.setFormattedAddress(null);
    this.appointmentFacade.setSearchClicked(false);
    this.appointmentFacade.resetStateForForm();

    this.cdr.markForCheck();
  }

  private filterEmptySelectedAppointments(combinedAppointmentLocations: CombinedAppointmentLocations) {
    const filteredSelectedLocations = combinedAppointmentLocations.selected.filter(
      (location: LocationWithAppointments) => location.appointments.length > 0
    );
    if (filteredSelectedLocations.length > 0) {
      combinedAppointmentLocations.selected = filteredSelectedLocations;
    }
  }

  private hasSelectedSpecificServiceCenters(
    availableServiceCenters: AvailableServiceCenters[],
    selectedCenterIds: string[]
  ): boolean {
    return availableServiceCenters.length !== selectedCenterIds.length;
  }

  private sortOutDuplicateTimeAppointments(appointments: CombinedAppointmentLocations) {
    const sortOutDuplicates = (location: LocationWithAppointments): void => {
      const filteredAppointments: Map<string, Appointment> = location.appointments.reduce(
        (set: Map<string, Appointment>, appointment: Appointment) => {
          if (!set.has(appointment.customerAppointmentStart)) {
            set.set(appointment.customerAppointmentStart, appointment);
          }
          return set;
        },
        new Map<string, Appointment>()
      );
      location.appointments = [...filteredAppointments.values()];
    };

    appointments.earlier.forEach((location: LocationWithAppointments) => sortOutDuplicates(location));
    appointments.selected.forEach((location: LocationWithAppointments) => sortOutDuplicates(location));
  }

  private initResetOnProcessFlowBack() {
    this.processFacade.currentProcessId$
      .pipe(
        pairwise(),
        withLatestFrom(this.appointmentFacade.availableServiceCenters$),
        tap(([[previous, current], availableServiceCenters]: [[ProcessId, ProcessId], AvailableServiceCenters[]]) => {
          if (
            current === "appointment" &&
            previous !== current &&
            (!availableServiceCenters || availableServiceCenters.length === 0)
          ) {
            this.resetForm();
          }
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private initSelectedAllServiceCenters(): void {
    this.appointmentFacade.selectedServiceCenterIds$
      .pipe(
        withLatestFrom(this.appointmentFacade.availableServiceCenters$),
        tap(
          ([selectedServiceCenters, availableServiceCenters]: [string[], AvailableServiceCenters[]]): boolean =>
            (this.selectedAllServiceCenters = selectedServiceCenters?.length === availableServiceCenters?.length)
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private initTrackSelectedBranches() {
    combineLatest([
      this.appointmentFacade.selectedServiceCenterIds$,
      this.combinedAppointmentLocations$,
      this.appointmentFacade.availableServiceCenters$,
      this.appointmentFacade.firstSearch$
    ])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged(
          (
            [prevSelectedScIds, _prevCombinedAppointmentLocations, _prevAvailableScs, _prevFirstSearch]: [
              string[],
              CombinedAppointmentLocations,
              AvailableServiceCenters[],
              boolean
            ],
            [currSelectedScIds, _currCombinedAppointmentLocations, _currAvailableScs, _currFirstSearch]: [
              string[],
              CombinedAppointmentLocations,
              AvailableServiceCenters[],
              boolean
            ]
          ) => prevSelectedScIds.join(",") === currSelectedScIds.join(",")
        ),
        filter(
          ([selectedScIds, combinedAppointmentLocations, _availableScs, _firstSearch]: [
            string[],
            CombinedAppointmentLocations,
            AvailableServiceCenters[],
            boolean
          ]) => selectedScIds.length > 0 && !!combinedAppointmentLocations
        )
      )
      .subscribe(
        ([selectedScIds, _combinedAppointmentLocations, availableScs, firstSearch]: [
          string[],
          CombinedAppointmentLocations,
          AvailableServiceCenters[],
          boolean
        ]) => {
          const eventLabel =
            selectedScIds.length === availableScs.length ? "all-branches" : `${selectedScIds.length}-branches`;

          this.trackingService.trackEvent({
            event: "ga-event",
            eventCategory: "olb",
            eventAction: firstSearch ? "appointment-filter-initial" : "appointment-filter-change",
            eventLabel
          });
          this.appointmentFacade.setFirstSearch(false);
        }
      );
  }
}
