import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { combineLatest, Observable, startWith } from "rxjs";
import { distinctUntilChanged, map, switchMap } from "rxjs/operators";
import { ContactDataFacade } from "@cg/olb/state";
import { TranslocoService } from "@jsverse/transloco";
import { ParagraphComponent } from "@cg/core/ui";
import { OlbHeadlineComponent } from "@cg/olb/shared";
import { BrandingComponent } from "@cg/shared";

@Component({
  selector: "cg-easiness-of-process",
  templateUrl: "./easiness-of-process.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, BrandingComponent, OlbHeadlineComponent, ParagraphComponent]
})
export class EasinessOfProcessComponent {
  public constructor(
    private readonly contactDataFacade: ContactDataFacade,
    private readonly translocoService: TranslocoService
  ) {}

  public get title$(): Observable<string> {
    return this.fullName$.pipe(
      distinctUntilChanged(),
      switchMap((fullName: string) =>
        fullName === ""
          ? this.translocoService.selectTranslate("easinessOfProcess.customWelcome.genericHeadline")
          : fullName
            ? this.translocoService.selectTranslate("easinessOfProcess.customWelcome.headline", { name: fullName })
            : this.translocoService.selectTranslate("easinessOfProcess.headline")
      ),
      startWith("")
    );
  }

  public get subTitle$(): Observable<string> {
    return this.fullName$.pipe(
      distinctUntilChanged(),
      switchMap((fullName: string) =>
        fullName || fullName === ""
          ? this.translocoService.selectTranslate("easinessOfProcess.customWelcome.subHeadline")
          : this.translocoService.selectTranslate("easinessOfProcess.subHeadline")
      ),
      startWith("")
    );
  }

  private get fullName$(): Observable<string | undefined> {
    return combineLatest([
      this.contactDataFacade.driverFirstname$,
      this.contactDataFacade.driverLastname$,
      this.contactDataFacade.insuranceHolderCompany$
    ]).pipe(
      distinctUntilChanged(),
      map(([firstName, lastName, insuranceHolderCompany]: [string, string, string]) => {
        if (!firstName && !lastName && !insuranceHolderCompany) {
          return undefined;
        }

        if (!firstName && lastName) {
          return "";
        }

        return firstName && lastName && !insuranceHolderCompany ? `${firstName} ${lastName}` : insuranceHolderCompany;
      })
    );
  }
}
