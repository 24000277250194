import { ChangeDetectionStrategy, Component } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ReactiveFormsModule } from "@angular/forms";
import { ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { combineLatest, iif, Observable, of } from "rxjs";
import { filter, switchMap, take } from "rxjs/operators";
import { DamageActions } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { TrackingEvent } from "@cg/analytics";
import { Icon } from "@cg/content-api/typescript-interfaces";
import { IconComponent } from "@cg/core/ui";
import { damageSizeBigIcon } from "@cg/icon";
import { CrackSizeExitIds, Insurance, isDirectResumeFn, OlbHeadlineComponent, Product } from "@cg/olb/shared";
import { DamageSize, RadioButtonGroup, RadioButtonGroupComponent, SplitViewComponent } from "@cg/shared";
import { DamageAssessmentContent } from "../../interfaces/damage-assessment-content";
import { DamageSizeComponent } from "../damage-size/damage-size.component";
import { damageSizeMultipleChipsTileContent } from "./models/damage-size-multiple-chips-tile-content.model";

@Component({
  selector: "cg-damage-size-multiple-chips",
  templateUrl: "./damage-size-multiple-chips.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoPipe,
    ReactiveFormsModule,
    OlbHeadlineComponent,
    IconComponent,
    RadioButtonGroupComponent,
    SplitViewComponent
  ]
})
export class DamageSizeMultipleChipsComponent extends DamageSizeComponent {
  public readonly damageTileContent: DamageAssessmentContent = damageSizeMultipleChipsTileContent;
  public readonly picture: Icon = damageSizeBigIcon;
  public readonly radioButtonGroup: RadioButtonGroup = {
    controlName: "damageSize",
    buttons: [
      {
        title: "damagesSizeMultipleChips.buttons.yes",
        radio: { id: "damage-size-multiple-chips-yes", value: DamageSize.BIG }
      },
      {
        title: "damagesSizeMultipleChips.buttons.no",
        radio: { id: "damage-size-multiple-chips-no", value: DamageSize.SMALL }
      }
    ]
  };

  public override postSaveForm(): void {
    if (this.damageSize === DamageSize.BIG) {
      super.updateDamage();
    }
  }

  public override getExitIdForSavedForm(): Observable<CrackSizeExitIds> {
    const isDirectResume = isDirectResumeFn(this._olbConfig.entryChannel);

    return combineLatest([
      iif(
        () => this.damageSize === DamageSize.SMALL,
        of(null),
        this.actions$.pipe(ofType(DamageActions.updateDamageSuccess), take(1))
      ),
      this.insuranceFacade.selectedInsurance$,
      this.productFacade.products$,
      this.insuranceFacade.vin$
    ]).pipe(
      filter(([_action, _, products, _vin]: [Action, Insurance, Product[], string]) => !isDirectResume || !!products),
      take(1),
      switchMap(([_action, insurance, products, vin]: [Action, Insurance, Product[], string]) =>
        this.exitIdService.getExitIdForDamageSize$(this.damageSize, insurance, isDirectResume, products, vin)
      ),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  protected trackDamageSize(): void {
    const damage = this.eventDamageTypeMapping.get(this.damageSize);

    this.trackingService.trackEvent({
      eventAction: "damage-size-multiple-chips-selection",
      eventLabel: damage,
      damage: {
        size: damage
      }
    } as Partial<TrackingEvent>);
  }

  public onSelectedValueChanged() {
    if (this.form.valid) {
      setTimeout(() => this.goForward("damage-size-multiple-chips"), 100);
    }
  }
}
