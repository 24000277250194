import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormGroup, ReactiveFormsModule, UntypedFormControl, Validators } from "@angular/forms";
import { Actions, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { combineLatest, iif, Observable, of } from "rxjs";
import { distinctUntilChanged, filter, first, switchMap, take } from "rxjs/operators";
import { OLB_CONFIG, OlbConfiguration } from "@cg/olb/configuration";
import { DamageActions, DamageFacade, DamageState, InsuranceFacade, ProcessFacade, ProductFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { TrackingDamageLocation, TrackingEvent, TrackingService } from "@cg/analytics";
import { Icon } from "@cg/content-api/typescript-interfaces";
import { AddFormControls } from "@cg/core/types";
import { addClickHandler, IconComponent } from "@cg/core/ui";
import { IS_SERVER_PLATFORM } from "@cg/core/utils";
import {
  damageLocationDefaultYellow,
  damageLocationEdgeYellow,
  damageLocationOtherYellow,
  damageLocationViewYellow
} from "@cg/icon";
import {
  ChooseDamagedWindscreenTypeExitIds,
  ExitIdService,
  Insurance,
  isDirectResumeFn,
  OLB_PROCESS_FLOW_MODEL,
  OlbHeadlineComponent,
  ProcessFlow,
  Product,
  ScrollService
} from "@cg/olb/shared";
import {
  DamageLocation,
  InfoButtonComponent,
  ProcessId,
  RadioButton,
  RadioButtonGroup,
  RadioButtonGroupComponent,
  RequiredService,
  SplitViewComponent
} from "@cg/shared";
import { ExitNodeResolverService } from "../../services/exit-node-resolver.service";
import { BaseDirective } from "../core/directives/base/base.directive";
import { DamageLocationDetailsComponent } from "./components/damage-location-details/damage-location-details.component";
import { DamageLocationForm } from "./interfaces/locate-damage-form.interface";
import { damageLocationOptions } from "./models/damage-location-options.model";

@Component({
  selector: "cg-damage-location",
  templateUrl: "./damage-location.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoPipe,
    ReactiveFormsModule,
    IconComponent,
    RadioButtonGroupComponent,
    InfoButtonComponent,
    SplitViewComponent,
    DamageLocationDetailsComponent,
    OlbHeadlineComponent
  ]
})
export class DamageLocationComponent
  extends BaseDirective<AddFormControls<DamageLocationForm>>
  implements OnInit, AfterViewInit, OnDestroy
{
  public hoverMode = false;
  public damageLocationOptions: RadioButtonGroup = damageLocationOptions;
  public requiredService: RequiredService;
  public defaultPictureContent: Icon = damageLocationDefaultYellow;
  public pictureContent: Icon = { ...this.defaultPictureContent };
  protected eventDamageLocationMapping = new Map<DamageLocation, TrackingDamageLocation>([
    [DamageLocation.VIEW, "drivers-field-of-vision"],
    [DamageLocation.EDGE, "window-edge"],
    [DamageLocation.OTHER, "anywhere-else"]
  ]);
  protected pictureMapping = new Map<DamageLocation, Icon>([
    [DamageLocation.VIEW, damageLocationViewYellow],
    [DamageLocation.EDGE, damageLocationEdgeYellow],
    [DamageLocation.OTHER, damageLocationOtherYellow]
  ]);
  protected labelMapping = new Map<DamageLocation, string>([
    [DamageLocation.VIEW, "damage-location-view"],
    [DamageLocation.EDGE, "damage-location-edge"],
    [DamageLocation.OTHER, "damage-location-other"]
  ]);
  private readonly FORWARD_DELAY_TIME = 800;

  // eslint-disable-next-line max-params
  public constructor(
    protected readonly cdr: ChangeDetectorRef,
    protected readonly processFacade: ProcessFacade,
    protected readonly exitNodeResolver: ExitNodeResolverService,
    protected readonly scrollService: ScrollService,
    protected readonly damageFacade: DamageFacade,
    protected readonly trackingService: TrackingService,
    @Inject(IS_SERVER_PLATFORM) public readonly isServer: boolean,
    protected readonly exitIdService: ExitIdService,
    protected readonly insuranceFacade: InsuranceFacade,
    protected readonly productFacade: ProductFacade,
    @Inject(OLB_CONFIG) protected readonly _olbConfig: OlbConfiguration,
    protected readonly actions$: Actions,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);
  }

  public get locateDamage(): DamageLocation {
    return this.form.controls.damageLocation.value;
  }

  public async ngOnInit() {
    await super.ngOnInit();

    this.processFacade.editOverlayClosed$
      .pipe(
        filter(({ processId }: { processId: ProcessId }) => processId === this.processId),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.form.reset();
        this.registerNumberHandlers();
      });

    this.form.valueChanges
      .pipe(
        distinctUntilChanged(
          (formA: Partial<{ damageLocation: DamageLocation }>, formB: Partial<{ damageLocation: DamageLocation }>) =>
            formA.damageLocation === formB.damageLocation
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((form: Partial<{ damageLocation: DamageLocation }>) => this.goForwardWithDelay(form.damageLocation));
  }

  public ngAfterViewInit() {
    super.ngAfterViewInit();
    this.registerNumberHandlers();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();

    this.damageFacade.setLocateDamage(null);
  }

  public initFormGroup(): void {
    this.form = new FormGroup<AddFormControls<DamageLocationForm>>({
      damageLocation: new UntypedFormControl("", Validators.required)
    });

    this.form.controls.damageLocation.valueChanges
      .pipe(
        filter(() => !this.hoverMode),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((val: DamageLocation) => {
        this.pictureContent = this.pictureMapping.get(val) ?? this.defaultPictureContent;
      });
  }

  public setFormValues(): void {
    this.damageFacade.damageLocation$
      .pipe(
        filter((locateDamage: DamageLocation) => !!locateDamage),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((locateDamage: DamageLocation) => {
        this.form.controls.damageLocation.setValue(locateDamage, { emitEvent: false, onlySelf: true });
        this.cdr.markForCheck();
      });
  }

  public getExitIdForSavedForm(): Observable<ChooseDamagedWindscreenTypeExitIds> {
    const isDirectResume = isDirectResumeFn(this._olbConfig.entryChannel);

    return combineLatest([
      iif(
        () => this.locateDamage === DamageLocation.OTHER,
        of(null),
        this.actions$.pipe(ofType(DamageActions.updateDamageSuccess), take(1))
      ),
      this.insuranceFacade.selectedInsurance$,
      this.productFacade.products$
    ]).pipe(
      filter(([_action, _, products]: [Action, Insurance, Product[]]) => !isDirectResume || !!products),
      take(1),
      switchMap(([_action, insurance, products]: [Action, Insurance, Product[]]) =>
        this.exitIdService.getExitIdForDamageLocation$(this.locateDamage, insurance, isDirectResume, products)
      ),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  public goForward(processId: ProcessId = "damage-location") {
    if (this.form.valid) {
      this.trackDamageLocation();
    }

    // current process id is checked so forwar d is not triggered on restore with resume id
    this.processFacade.currentProcessId$
      .pipe(
        first(),
        filter((id: ProcessId) => id === processId)
      )
      .subscribe(() => super.goForward());
  }

  public goForwardWithDelay(value: string) {
    if ([null, ""].includes(value)) {
      return;
    }

    setTimeout(() => this.goForward(), this.FORWARD_DELAY_TIME);
  }

  public saveForm(): void {
    this.damageFacade.setLocateDamage(this.locateDamage);
  }

  public override postSaveForm() {
    super.postSaveForm();

    if ([DamageLocation.EDGE, DamageLocation.VIEW].includes(this.locateDamage)) {
      this.updateDamage();
    }
  }

  public updateDamage(): void {
    this.damageFacade.damageState$
      .pipe(
        filter((damageState: DamageState) => this.locateDamage === damageState.damageLocation),
        take(1),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((damageState: DamageState) => {
        this.damageFacade.updateDamage(damageState);
      });
  }

  public onHover(item: RadioButton) {
    this.pictureContent = this.pictureMapping.get(item.radio.value as unknown as DamageLocation);
  }

  public onHoverLeave() {
    const damageLocation = this.form.controls.damageLocation.value;
    this.pictureContent = this.pictureMapping.get(damageLocation) ?? this.defaultPictureContent;

    if (!damageLocation) {
      this.registerNumberHandlers();
    }
  }

  public registerNumberHandlers() {
    this.registerNumberClickHandler();
    this.registerNumberHoverHandler();
  }

  public registerNumberClickHandler() {
    if (this.isServer) {
      return;
    }

    setTimeout(() => {
      addClickHandler("dl_id_1", this.createClickHandler(DamageLocation.EDGE));
      addClickHandler("dl_id_2", this.createClickHandler(DamageLocation.VIEW));
      addClickHandler("dl_id_3", this.createClickHandler(DamageLocation.OTHER));
    }, 300);
  }

  public registerNumberHoverHandler() {
    if (this.isServer) {
      return;
    }

    setTimeout(() => {
      this.addNumberHoverHandler("dl_id_1", DamageLocation.EDGE);
      this.addNumberHoverHandler("dl_id_2", DamageLocation.VIEW);
      this.addNumberHoverHandler("dl_id_3", DamageLocation.OTHER);
    }, 300);
  }

  protected trackDamageLocation() {
    const eventDamageLocation = this.eventDamageLocationMapping.get(this.locateDamage);
    this.trackingService.trackEvent({
      eventAction: "damage-location-selection",
      eventLabel: eventDamageLocation,
      damage: {
        location: eventDamageLocation
      }
    } as Partial<TrackingEvent>);
  }

  private createClickHandler(damageLocation: DamageLocation): () => void {
    return () => {
      if (this.hoverMode) {
        this.hoverMode = false;
      }

      this.form.controls.damageLocation.setValue(damageLocation);
    };
  }

  private addNumberHoverHandler(id: string, damageLocation: DamageLocation) {
    const element = document.getElementById(id);

    if (!element) {
      return;
    }

    const label = this.getLabelForDamageLocation(damageLocation);

    element.addEventListener("mouseover", () => {
      this.hoverMode = true;
      label.classList.add("hover");
    });

    element.addEventListener("mouseout", () => {
      label.classList.remove("hover");
      this.hoverMode = false;
    });
  }

  private getLabelForDamageLocation(damageLocation: DamageLocation) {
    const id = this.labelMapping.get(damageLocation);
    return document.querySelector(`label[for=${id}]`);
  }

  protected restoreFromEntryState() {
    this.damageFacade.damageLocation$.pipe(take(1)).subscribe((locateDamage: DamageLocation) => {
      setTimeout(() => {
        this.form.controls.damageLocation.setValue(locateDamage, { emitEvent: false, onlySelf: true });
        this.cdr.markForCheck();
        this.goForward();
      }, 100);
    });
  }
}
